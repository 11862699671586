/* You can add global styles to this file, and also import other style files */

html,
body {
  min-width: 0 !important;
}

#zmmtg-root {
  display: none;
}
